/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useGetListingLotsQuery } from '../../../components/api'
import { hideNull } from '../../../components/hideNull'
import Spinner from '../../../components/spinner'
import { NOOP, triggerDownloadOfCSV } from '../../../components/utils'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { globalListingNumber = 1, listingId = 'PLAYBACK1' } = props

  // state
  const [selectedBuyers, setSelectedBuyers] = React.useState([])
  const [selectedLots, setSelectedLots] = React.useState([])
  const [selectedBuyersSkuBids, setSelectedBuyersSkuBids] = React.useState([])

  const [emptyLots, setEmptyLots] = React.useState([])

  const handleSelectedLots = (e) => {
    setSelectedLots(e)
    setEmptyLots([])
  }
  const handleSelectedBuyer = (e) => {
    setSelectedBuyers(e)
    setEmptyLots([])
  }
  // query
  const listingLotQuery = useGetListingLotsQuery(listingId)
  const { data: { buyers = [], listing_lots = [] } = {} } = listingLotQuery

  // options manipulate
  //
  const listing_lots_clone = []
  listing_lots.forEach(e => { listing_lots_clone.push(e) })
  listing_lots_clone.splice(0, 0, { lot_number: 'All', id: 'LT' })

  const buyers_clone = []
  buyers.forEach(e => { buyers_clone.push(e) })
  buyers_clone.splice(0, 0, { name: 'All', id: 'all' })
  //
  //

  // handle selecting all
  React.useEffect(() => {
    if (selectedLots.length > 0) {
      selectedLots.forEach(e => { e.lot_number === 'All' && setSelectedLots(listing_lots) })
    }
  }, [selectedLots])

  React.useEffect(() => {
    if (selectedBuyers.length > 0) {
      selectedBuyers.forEach(e => { e.name === 'All' && setSelectedBuyers(buyers) })
    }
  }, [selectedBuyers])

  const onDownload = () => {
    // create csv file...
    let tableData = []
    const tableHeader = ['LotId',
      'SkuId',
      'Description',
      'Grade',
      'Quantity']

    selectedBuyers.forEach(buyer => tableHeader.push(buyer.name))

    // for (let ix = 0; ix < selectedBuyers.length; ix++) {
    //   const element = selectedBuyers[ix].name
    //   tableHeader.push(element)
    // }
    // selectedLots.forEach(element => {})

    for (let i = 0; i < selectedLots.length; i++) {
      const element = selectedLots[i]

      // create a map of buyer sku bids
      const mapCompanyIdToSkuBids = element.buyer_bids.reduce((prev, curr) => {
        prev[curr.company_id] = curr.sku_bids
        return prev
      }, {})
      tableData = tableData.concat(element.listing_lot_skus.map(sku => {
        const data = [
          hideNull(sku.lot_number),
          hideNull(sku.id),
          hideNull(sku.sku_description),
          hideNull(sku.grade),
          hideNull(sku.qty)
        ]
        // add each buyer's bid for the current sku... (see below)
        selectedBuyers.forEach(buyer => {
          const sku_bids = mapCompanyIdToSkuBids[buyer.company_id] || {}
          const skubid = sku_bids[sku.id]
          data.push(hideNull(skubid))
        })

        // dont' forget to return the data! :)
        return data
      }))
    }
    const filename = `Listing${globalListingNumber}` + selectedLots.map(lot => { return '-lot' + lot.lot_number }) + selectedBuyers.map(lot => { return '-' + lot.name }) + '-UnitPrices.csv'
    triggerDownloadOfCSV(filename,
      tableHeader,
      tableData)
  }

  // functions
  const onHide = () => {
    setSelectedBuyers([])
    setSelectedLots([])
    setOpenModal(false)
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Download unit prices</h5>
                <p>Select a buyer to download their lot breakdown.</p>

                { listingLotQuery.isLoading
                  ? <div className="d-flex justify-content-center m-4"><Spinner /></div>
                  : <>
                {/* choose buyer */}
                <Typeahead
                    clearButton
                    filterBy={_ => true}
                    id="basic-typeahead-single"
                    labelKey="name"
                    options={buyers_clone}
                    multiple
                    placeholder="Select a buyer"
                    onChange={handleSelectedBuyer}
                    selected={selectedBuyers}
                    className="mb-3 custom-typehead-style"
                />
                {/* choose lot */}

                <Typeahead
                    clearButton
                    filterBy={_ => true}
                    id="basic-typeahead-single-lot"
                    // labelKey={(option) => `Lot ${option.lot_number}`}
                    labelKey={(option) => option.lot_number === 'All' ? 'All' : `Lot ${option.lot_number}`}
                    options={listing_lots_clone}
                    multiple
                    placeholder="Select a lot"
                    onChange={handleSelectedLots}
                    selected={selectedLots}
                    className="mb-3 custom-typehead-style"
                />

              </>}

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={onDownload} variant="primary"
                      disabled={listingLotQuery.isFetching || selectedBuyers.length === 0 || selectedLots.length === 0 }
                      >
                        { listingLotQuery.isFetching ? <Spinner /> : <span>Download</span>}
                    </Button>
                </div>

                {/* show error if buyer has no bids for selected lot */}
                { selectedBuyers.length > 0 && selectedLots.length > 0 && false && <Alert variant="danger" className="mb-0 mt-3 text-center">Buyer has no bids on {emptyLots && emptyLots.length > 0 && emptyLots.map(lot => { return ' ' + lot + ' ' })} .</Alert> }
                { listingLotQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error retrieving bids. ' + listingLotQuery.error.message}</Alert> }

                { process.env.NODE_ENV !== 'production' && <>

                <pre> selectedBuyersSkuBids = {JSON.stringify(selectedBuyersSkuBids, null, 2)}</pre>
                    <pre>selectedBuyers = {JSON.stringify(selectedBuyers, null, 2)}</pre>
                    <pre>selectedLots = {JSON.stringify(selectedLots, null, 2)}</pre>
                    <pre>listingLotQuery = {JSON.stringify(listingLotQuery, null, 2)}</pre>
                </>}
            </Modal.Body>
        </Modal>
      </>
}

export default Page
