/* eslint-disable camelcase */

import Chip from '@material-ui/core/Chip'
import { TableFilterList } from 'mui-datatables'
import React from 'react'
import { Alert } from 'react-bootstrap'
import VendorDownloadUnitPriceModal from '../../pages/app/kitchensink/vendorDownloadUnitPriceModal'
import UpdateLotBidsModal from '../../pages/app/kitchensink/vendorUpdateBiddingActivityLotsModal'
import UpdateSkuBidsModal from '../../pages/app/kitchensink/vendorUpdateBiddingActivitySkuModal'
import { useGetCountriesQuery, useGetCurrenciesQuery, useGetListingLotsQuery, useGetListingQuery, useGetListingStatusesQuery } from '../api'
import { useGetListingLotSkusQuery } from '../api/BuyerApi'
import LotNavButtons from '../biddingActivityTabs/LotNavButtons'
import Countdown from '../Countdown'
import MUIDataTable, { headerRightAlign } from '../MuiDatatableUtils'
import MuiToolDownloadUnitPrice from '../MuiToolbarDownloadUnitPrice'
import MuiToolbarEditBids from '../MuiToolbarEditBids'
import Spinner from '../spinner'
import { useWatchListingSocket, WebSocketStatus } from '../websocket'

const isNullOrUndef = o => typeof o === 'undefined' || o === null
const isNotNullOrUndef = o => !isNullOrUndef(o)

const CustomChip = ({ label, onDelete }) => {
  return (
        <Chip
            variant="outlined"
            color="secondary"
            label={label}
            onDelete={onDelete}
        />
  )
}

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />
}

/**
 * Returns true if awardLevelSelection is 'award_sku.
 */
const isAwardLevelSelectionASku = (awardLevelSelection) => awardLevelSelection === 'award_sku'
const isAuctionTypeSelectionATender = (saleTypeSelection) => saleTypeSelection === 'tender'

/*
 * Page
 */
const Page = (props) => {
  // props
  const { listingId } = props

  // state
  const [wsstatus, setWsstatus] = React.useState('connecting')
  const [selectedLotId, setSelectedLotId] = React.useState('all')
  const [selectedLot, setSelectedLot] = React.useState({})
  const [openUpdateBidsModal, setOpenUpdateBidsModal] = React.useState(false)
  const [openDownloadUnitPriceModal, setOpenDownloadUnitPriceModal] = React.useState(false)

  // query
  const listingQuery = useGetListingQuery({ listingId, isVendor: true })
  const {
    isLoading = false,
    data: {
      global_listing_number = 1,
      listing_lots: listingLots = [],
      award_level_selection: awardLevelSelection = null,
      sale_type_selection: saleTypeSelection = null
    } = {}
  } = listingQuery
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: selectedLotId, listingLots, isVendor: true })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot
  const listingLotsQuery = useGetListingLotsQuery(listingId)
  const statusesQuery = useGetListingStatusesQuery()
  const countriesQuery = useGetCountriesQuery()
  const currenciesQuery = useGetCurrenciesQuery()

  const isTender = isAuctionTypeSelectionATender(saleTypeSelection)
  const isAwardLevelSku = isAwardLevelSelectionASku(awardLevelSelection)

  /**
   * Retrieves the LOT for the given row.
   */
  const getLotRow = (meta) => {
    return listingLots[meta.currentTableData[meta.rowIndex].index]
  }

  /**
   * Retrieves the SKU for the given row.
   */
  const getSkuRow = (meta) => {
    return listingLotSkus[meta.currentTableData[meta.rowIndex].index]
  }

  const { data: { buyers: tmpBuyers = [], listing_lots = [] } = {} } = listingLotsQuery
  /** @type {Buyer[]} */
  const buyers = tmpBuyers

  // connect for socket updates...
  useWatchListingSocket({ listingId, handleStatusUpdate: setWsstatus, refreshEntireListing: true, isVendor: true })

  // ANCHOR Dynamic LOT Columns - Buyers
  const buyerColumnsLots = listingLotsQuery.isSuccess
    ? buyers.map(buyer => ({
      label: buyer.name,
      name: 'id',
      options: {
        companyId: `cid_${buyer.company_id}`,
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (price, meta) => {
          const data = getLotRow(meta)
          /* company_id / price */
          const buyerx = data[`cid_${buyer.company_id}`]
          return buyerx
            ? (<div className={`text-right bidstatusindicator bidstatusindicator-${buyerx.colour_indicator}`}
        >{isNotNullOrUndef(buyerx.price) ? ('$' + Math.round(buyerx.price).toLocaleString()) : ''}</div>)
            : (<div style={{ minWidth: '200px' }}></div>)
        }
      }
    }))
    : []

  // ANCHOR Dynamic SKU Columns - Buyers
  const buyerColumnsSku = listingLotsQuery.isSuccess
    ? buyers.map(buyer => ({
      label: buyer.name,
      name: `cid_${buyer.company_id}`,
      options: {
        companyId: `cid_${buyer.company_id}`,
        customHeadLabelRender: headerRightAlign,
        customBodyRender: (price, meta) => {
          const data = getSkuRow(meta)
          /* company_id / price */
          const { highest_company_id } = data
          const buyerx = data[`cid_${buyer.company_id}`]
          console.debug('buyerx ', buyerx)
          if (buyerx && buyerx.price !== 0) { // don't show zero bids, for sku tenders...
            const colour = highest_company_id === buyerx.company_id ? 'green' : 'transparent'
            return <div>
              <div className={`text-right bidstatusindicator bidstatusindicator-${colour}`}>
                {isNotNullOrUndef(buyerx.price) ? ('$' + Math.round(buyerx.price).toLocaleString()) : ''}
              </div>
              <div className="text-right bidstatusindicator-subtotal"> {isNotNullOrUndef(buyerx.price) ? ('$' + Math.round(buyerx.price * data.quantity).toLocaleString()) : ''}</div>
            </div>
          } else {
            return <div style={{ minWidth: '200px' }}></div>
          }
        }
      }
    }))
    : []

  // ANCHOR LOT Columns
  const columnsLot = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'End Time',
          name: 'end_time',
          options: {
            customBodyRender: (x) => <Countdown
            className="text-nowrap"
            toDate={new Date(x)}
            showOnEnd=''
          />
          }
        }
      : null,
    { label: 'Lot', name: 'lot_number' },
    { label: 'Description', name: 'lot_description' },
    {
      label: 'Quantity',
      name: 'quantity'
    },
    ...buyerColumnsLots
  ].filter(f => f !== null)

  // ANCHOR SKU Columns
  const columnsSku = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'End Time',
          name: 'end_time',
          options: {
            customBodyRender: x => <Countdown
              className="text-nowrap"
              toDate={new Date(x)}
              showOnEnd=''
            />
          }
        }
      : null,
    { label: 'Lot', name: 'lot_number' },
    { label: 'Description', name: 'sku_description' }, //, options: { customBodyRender: (x, meta) => skuToDescription(getSkuRow(meta)) } },
    { label: 'Grade', name: 'grade' },
    { label: 'Quantity', name: 'qty' },
    ...buyerColumnsSku
  ].filter(f => f !== null)

  return <>
        <div className="card-body rounded-soft bg-white">

          {/* lot buttons */}
          { isAwardLevelSelectionASku(awardLevelSelection) && <LotNavButtons
            listingQuery={listingQuery}
            selectedLotId={selectedLotId}
            setSelectedLotId={setSelectedLotId}
            setSelectedLot={setSelectedLot}
          />}

          <WebSocketStatus status={wsstatus} />

          {/* show spinner if loading, otherwise show content */}
          { listingQuery.isLoading || statusesQuery.isLoading || countriesQuery.isLoading || currenciesQuery.isLoading
            ? <div className="d-flex justify-content-center p-5"><Spinner /></div>
            : listingQuery.isError
              ? (<Alert variant="danger" className="my-4">{listingQuery.error.message}</Alert>)
              : listingQuery.isSuccess && (
              <div className="container-fluid mb-4">

                {/* summary table */}

                <div className="mt-3">

                  {/* ANCHOR Auction SKU Table */}
                  { !isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { !isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { !isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotSkusQuery.isSuccess && <MUIDataTable
                            data={listingLotSkus}
                            columns={columnsSku}
                            options={{
                              selectableRows: 'none', // single / multiple
                              pagination: true,
                              jumpToPage: false,
                              rowsPerPage: 100,
                              print: false,
                              search: true,
                              download: true,
                              sort: false,
                              filter: true,
                              viewColumns: true,
                              elevation: 0,
                              components: {
                                TableFilterList: CustomFilterList
                              },
                              customToolbar: () => <>
                                <MuiToolbarEditBids onEditBids={() => setOpenUpdateBidsModal(true)} />
                              </>,
                              setTableProps: () => ({ size: 'small' }),
                              onDownload: (buildHead, buildBody, columns, data) => {
                                // add dynamic buyer prices
                                const newData = listingLotSkus.map((sku, index) => {
                                  return { index, data: columns.map(col => col.companyId ? (sku[col.companyId] ? sku[col.companyId].price : '') : sku[col.name]) }
                                })
                                return '\uFEFF' + buildHead(columns) + buildBody(newData)
                              }
                            }}
                            />
                          }

                  {/* ANCHOR Auction LOT Table */}
                  { !isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { !isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { !isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isSuccess && <MUIDataTable
                            data={listing_lots}
                            columns={columnsLot}
                            options={{
                              selectableRows: 'none', // single / multiple
                              pagination: true,
                              jumpToPage: false,
                              rowsPerPage: 100,
                              print: false,
                              search: true,
                              download: true,
                              sort: false,
                              filter: true,
                              viewColumns: true,
                              elevation: 0,
                              components: {
                                TableFilterList: CustomFilterList
                              },
                              onDownload: (buildHead, buildBody, columns, data) => {
                                // add dynamic buyer prices
                                const newData = listingLots.map((lot, index) => {
                                  return { index, data: columns.map(col => col.companyId ? (lot[col.companyId] ? lot[col.companyId].price : '') : lot[col.name]) }
                                })
                                return '\uFEFF' + buildHead(columns) + buildBody(newData)
                              },
                              customToolbar: () => {
                                return (<>
                                <MuiToolbarEditBids onEditBids={() => setOpenUpdateBidsModal(true)} />
                              </>)
                              },
                              setTableProps: () => ({ size: 'small' })
                            }}
                            />
                          }

                  {/* ANCHOR Tender LOT Table */}
                  { isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { isTender && !isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isSuccess && <MUIDataTable
                            data={listing_lots}
                            columns={columnsLot}
                            options={{
                              selectableRows: 'none', // single / multiple
                              pagination: true,
                              jumpToPage: false,
                              rowsPerPage: 100,
                              print: false,
                              search: true,
                              download: true,
                              sort: false,
                              filter: true,
                              viewColumns: true,
                              elevation: 0,
                              components: {
                                TableFilterList: CustomFilterList
                              },
                              onDownload: (buildHead, buildBody, columns, data) => {
                                // add dynamic buyer prices
                                const newData = listingLots.map((lot, index) => {
                                  return { index, data: columns.map(col => col.companyId ? (lot[col.companyId] ? lot[col.companyId].price : '') : lot[col.name]) }
                                })
                                return '\uFEFF' + buildHead(columns) + buildBody(newData)
                              },
                              customToolbar: () => {
                                return (<>
                                {!isAwardLevelSelectionASku(awardLevelSelection) && <MuiToolDownloadUnitPrice onDownloadPrice={() => setOpenDownloadUnitPriceModal(true)} />}
                              </>)
                              },
                              setTableProps: () => ({ size: 'small' })
                            }}
                            />
                          }

                  {/* ANCHOR Tender SKU Table */}
                  { isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { isTender && isAwardLevelSelectionASku(awardLevelSelection) && listingLotsQuery.isSuccess && <MUIDataTable
                            data={listingLotSkus}
                            columns={columnsSku}
                            options={{
                              selectableRows: 'none', // single / multiple
                              pagination: true,
                              jumpToPage: false,
                              rowsPerPage: 100,
                              print: false,
                              search: true,
                              download: true,
                              sort: false,
                              filter: true,
                              viewColumns: true,
                              elevation: 0,
                              components: {
                                TableFilterList: CustomFilterList
                              },
                              onDownload: (buildHead, buildBody, columns, data) => {
                                // add dynamic buyer prices
                                const newData = listingLotSkus.map((sku, index) => {
                                  return { index, data: columns.map(col => col.companyId ? (sku[col.companyId] ? sku[col.companyId].price : '') : sku[col.name]) }
                                })
                                return '\uFEFF' + buildHead(columns) + buildBody(newData)
                              },
                              customToolbar: () => {
                                return (<>
                                {!isAwardLevelSelectionASku(awardLevelSelection) && <MuiToolDownloadUnitPrice onDownloadPrice={() => setOpenDownloadUnitPriceModal(true)} />}
                              </>)
                              },
                              setTableProps: () => ({ size: 'small' })
                            }}
                            />
                          }

                  </div>
              </div>
              )
          }

          { process.env.NODE_ENV !== 'production' && <pre>listingQuery={JSON.stringify(listingQuery.data, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingLotsQuery={JSON.stringify(listingLotsQuery.data, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingLotSkusQuery={JSON.stringify(listingLotSkusQuery, null, 2)}</pre>}

        </div>

        {/* auction -> update bids */}
        { !isTender && isAwardLevelSku && <UpdateSkuBidsModal listingId={listingId} openModal={openUpdateBidsModal} setOpenModal={setOpenUpdateBidsModal} /> }
        { !isTender && !isAwardLevelSku && <UpdateLotBidsModal listingId={listingId} openModal={openUpdateBidsModal} setOpenModal={setOpenUpdateBidsModal} /> }

        {/* auction -> download unit prices */}
        { isTender && <VendorDownloadUnitPriceModal
            listingId={listingId}
            globalListingNumber={global_listing_number}
            openModal={openDownloadUnitPriceModal}
            setOpenModal={setOpenDownloadUnitPriceModal}
          />
        }

      </>
}

export default Page
