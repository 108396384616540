/* eslint-disable camelcase */

import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useGetListingQuery } from '../../../../components/api'
import Countdown from '../../../../components/Countdown'
import { SimpleTabs } from '../../../../components/Form'
import Layout from '../../../../components/Layout'
import ListingStatus from '../../../../components/ListingStatus'
import { vendorNavTo } from '../../../../components/navigation'
import VendorBiddingActivityTab from '../../../../components/viewListingTabs/vendorBiddingActivityTab'
import VendorViewTab from '../../../../components/viewListingTabs/vendorViewTab'

const Page = (props) => {
  const { listingId } = props
  const listingQuery = useGetListingQuery({ listingId, isVendor: true }) // N.B. from this page, we are always the vendor!
  const { data: { global_listing_number = '' } = {} } = listingQuery

  // react-state
  const [page, setPage] = React.useState(0)

  return <Layout>

        <Layout.Title>
            <div className="d-flex align-items-center">
              <h5>List Number: {global_listing_number}</h5>
              { listingQuery.isSuccess && <ListingStatus status={listingQuery.data.status} /> }
            </div>
            <Button variant="primary" type="submit" onClick={() => vendorNavTo.editListingPage(listingId)}>Edit Listing</Button>
        </Layout.Title>

        <Layout.CardGrey>

            <SimpleTabs elements={[
              { label: 'Listing', onClick: () => setPage(0) },
              { label: 'Bid Activity', onClick: () => setPage(1) }
              // { label: 'Product Manifest', onClick: () => setPage(2) },
              // { label: 'IMEI Upload', onClick: () => setPage(3) }
            ]} selectedIndex={page} size="md">

              {/* show time remaining */}
              <div className="d-flex flex-grow-1 align-items-center justify-content-end">
                {listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'disabled' && (
                  <Badge variant="outline-danger" className="countdown countdown-lg">
                    <span className="fas fa-clock"></span> End time: <span style={{ display: 'inline-block', minWidth: '55px' }}>
                      <Countdown toDate={new Date(listingQuery.data.end_time)} showOnEnd='Ended' />
                    </span>
                  </Badge>
                ) }
              </div>

            </SimpleTabs>

            {/* Vendor */}
            { page === 0 && <VendorViewTab {...props} />}
            { page === 1 && <VendorBiddingActivityTab {...props} />}
            {/* { page === 2 && <VendorProductManifestTab {...props} />}
            { page === 3 && <VendorImeiTab {...props} />} */}

        </Layout.CardGrey>

    </Layout>
}

export default Page
